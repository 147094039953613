/* prettier-ignore */
@use 'sass:math';
@import 'colors';
$prefix: 'sparrow-';
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;
// scss-docs-end container-max-widths

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1540px,
) !default;
// scss-docs-end grid-breakpoints

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width: 2rem !default;

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.8,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 7.5,
  9: $spacer * 10,
  10: $spacer * 12.5,
  11: $spacer * 15
) !default;
// scss-docs-end spacer-variables-maps

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $gray-800 !default;

// Min contrast ratio
$min-contrast-ratio: 2 !default;

// Border
// 
// Define border radius styles and more.
$border-color: $gray-300 !default;
$border-width: 1px !default;

// scss-docs-start border-styles
$border-styles : (
  dotted: 'dotted !important',
  dashed: 'dashed !important',
  none: 'none !important',
  hidden: 'hidden !important',
) !default;
// scss-docs-end border-styles

// scss-docs-start box-shadow-variables
$box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07) !default;
$box-shadow-sm:  0 .125rem .25rem rgba($black, .075) !default;
$box-shadow-lg: 0 1rem 4rem rgba($black, 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, .075) !default;
// scss-docs-end box-shadow-variables

// Links
//
// Style anchor elements.
$link-decoration: none !default;
$link-hover-decoration: underline !default;

// scss-docs-start border-radius-variables
$border-radius: 0.1875rem !default;
$border-radius-sm: 0.125rem !default;
$border-radius-lg: 0.313rem !default;
$border-radius-xl: 0.625rem !default;
$border-radius-xxl: 0.938rem !default;
$border-radius-pill: 50rem !default;
// scss-docs-end border-radius-variables

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
$font-family-sans-serif: 'Lato', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$font-family-serif: 'PT Serif', Times, 'Times New Roman', Georgia, serif !default;

$font-family-base: var(--#{$prefix}font-serif) !default;
$font-family-secondary: var(--#{$prefix}font-sans-serif) !default;

$type-scale: 1.333;
$font-size-base: 1rem !default;

$font-sizes: (
  10: $font-size-base * 0.75,
  9: $font-size-base,
  8: $font-size-base * 1.333,
  7: $font-size-base * 1.777,
  6: $font-size-base * 2.369,
  5: $font-size-base * 3.157,
  4: $font-size-base * 4.199,
  3: $font-size-base * 5.584,
  2: $font-size-base * 7.427,
  1: $font-size-base * 9.878,
) !default;

$h1-font-size: map_get($font-sizes, 5) !default;
$h2-font-size: map_get($font-sizes, 6) !default;
$h3-font-size: map_get($font-sizes, 7) !default;
$h4-font-size: map_get($font-sizes, 8) !default;
$h5-font-size: map_get($font-sizes, 9) !default;
$h6-font-size: map_get($font-sizes, 10) !default;

$font-size-lg: map_get($font-sizes, 8) !default;
$font-size-sm:  map_get($font-sizes, 10) !default;

$font-weight-thin: 100 !default;
$font-weight-lighter: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;
$font-weight-black: 900 !default;

$line-height-base: 1.45 !default;

$headings-font-family: $font-family-secondary !default;
$headings-font-weight: $font-weight-black !default;
$headings-color: var(--#{$prefix}emphasis-color) !default;

$lead-font-size: $font-size-lg !default;
$lead-font-weight: $font-weight-normal !default;

$small-font-size: 75% !default;

$blockquote-font-size: $font-size-lg !default;
// scss-docs-end font-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: map_get($font-sizes, 1),
  2: map_get($font-sizes, 2),
  3: map_get($font-sizes, 3),
  4: map_get($font-sizes, 4),
  5: map_get($font-sizes, 5),
  6: map_get($font-sizes, 6),
) !default;

$display-font-weight: $font-weight-black !default;
$display-line-height: 1 !default;
// scss-docs-end display-headings

// Accordion
// 
// scss-docs-start accordion-variables
$accordion-border-color: rgba(var(--#{$prefix}emphasis-color-rgb), .125) !default;
$accordion-color: rgba(var(--#{$prefix}dark-rgb), .94) !default; 
$accordion-button-active-color: rgba(var(--#{$prefix}dark-rgb), .94) !default;
$accordion-button-active-bg: var(--#{$prefix}body-bg) !default;
$accordion-bg: var(--#{$prefix}body-bg) !default;
$accordion-icon-width: .6rem !default;
$accordion-icon-transform: rotate(90deg) !default;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#000'><path fill-rule='evenodd' d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/></svg>") !default;
$accordion-button-active-icon: $accordion-button-icon !default;
$accordion-body-padding-x: 3rem !default;
// scss-docs-end accordion-variables

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-striped-order: even !default;
$table-cell-padding-y: 0.75rem !default;
$table-cell-padding-x: 0.75rem !default;
$table-group-separator-color: inherit !default;
$table-color: $dark !default;
// scss-docs-end table-variables

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start btn-sparrow-variables
$btn-sparrow-box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba($black, 0.07), 0 1px 2px 0 rgba($black, 0.08) !default; // Ntr
$btn-sparrow-hover-box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1), 0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba($black, 0.08), 0 1px 2px 0 rgba($black, 0.08) !default;
// scss-docs-end btn-sparrow-variables

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.8rem !default;
$input-btn-padding-x: 2.5rem !default;

$input-btn-padding-y-sm: 0.4rem !default;
$input-btn-padding-x-sm: 1.2rem !default;

$input-btn-padding-y-lg: 1.2rem !default;
$input-btn-padding-x-lg: 5rem !default;

$btn-font-weight: $font-weight-bold !default;

$btn-border-radius-lg: $border-radius !default;;
$btn-border-radius-sm: 0.2rem !default;
// scss-docs-end input-btn-variables

// scss-docs-start form-input-variables
$input-group-addon-bg: var(--#{$prefix}secondary-bg) !default;
$input-color: var(--#{$prefix}body-highlight-color) !default;

$input-padding-x: $input-btn-padding-y !default;
$input-padding-x-sm: $input-btn-padding-y-sm !default;
$input-padding-x-lg: $input-btn-padding-y-lg !default;
// scss-docs-end form-input-variables

// scss-docs-start form-file-variables
$form-file-button-bg: var(--#{$prefix}dark) !default;
$form-file-button-hover-bg: var(--#{$prefix}dark) !default;
$form-file-button-color: var(--#{$prefix}light) !default;
// scss-docs-end form-file-variables

// Z-index master list
// 
// scss-docs-start zindex-stack
$zindex-sticky: 1015 !default;
$zindex-modal-backdrop: 10040 !default;
$zindex-modal: 10050 !default;
// scss-docs-end zindex-stack

// Navbar
// 
// scss-docs-start navbar-variables
$navbar-padding-y: map_get($spacers, 2) !default;
$navbar-padding-x: $spacer !default;

$navbar-dark-color: rgba(var(--#{$prefix}white-rgb), .7) !default;
$navbar-dark-hover-color: rgba(var(--#{$prefix}white-rgb), .8) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !default;

$navbar-light-color: rgba(var(--#{$prefix}emphasis-color-rgb), .55) !default;
$navbar-light-hover-color: rgba(var(--#{$prefix}emphasis-color-rgb), .9) !default;
$navbar-light-disabled-color: rgba(var(--#{$prefix}emphasis-color-rgb), .25) !default;

$navbar-toggler-focus-width: 0;
// scss-docs-end navbar-variables

// scss-docs-start navbar-variables
$navbar-brand-font-size: map_get($font-sizes, 8) !default;
$navbar-brand-font-weight: $font-weight-bold !default;
// scss-docs-end navbar-variables

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-link-hover-color: var(--#{$prefix}body-highlight-color) !default;
$dropdown-link-hover-bg: var(--#{$prefix}secondary-bg) !default;
$dropdown-divider-bg: var(--#{$prefix}border-color) !default;

$dropdown-font-size: $font-size-base * 0.8 !default;

$dropdown-item-padding-y: map_get($spacers, 1) !default;
$dropdown-item-padding-x: map_get($spacers, 3) !default;
$dropdown-padding-y: map_get($spacers, 3) !default;
// scss-docs-end dropdown-variables



// Pagination
// 
// scss-docs-start pagination-variables
$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;

$pagination-color: var(--#{$prefix}emphasis-color) !default;

$pagination-hover-color: var(--#{$prefix}white) !default;
$pagination-hover-bg: var(--#{$prefix}black) !default;
$pagination-hover-border-color: var(--#{$prefix}emphasis-color) !default;

$pagination-active-bg: var(--#{$prefix}black) !default;
$pagination-active-border-color: var(--#{$prefix}emphasis-color) !default;
// scss-docs-end pagination-variables

// Cards
// 
// scss-docs-start card-variables
$card-spacer-y: map_get($spacers, 5) !default;
$card-spacer-x: map_get($spacers, 5) !default;
$card-title-spacer-y: map_get($spacers, 3) !default;
$card-border-width: 1px !default;
$card-border-color: rgba(var(--#{$prefix}emphasis-color-rgb), 0.125) !default;
$card-cap-bg: rgba($black, 0.03) !default;
$card-cap-padding-y: $spacer !default;
$card-cap-padding-x: 3rem !default;
// scss-docs-start card-variables

// Tooltips
// 
// scss-docs-start tooltip-variables
$tooltip-padding-y: .5rem !default;
$tooltip-font-size: map_get($font-sizes, 10) !default;
// scss-docs-end tooltip-variables

// Badges
// 
// scss-docs-start badge-variables
$badge-font-weight: $font-weight-black !default;
$badge-padding-y: 0.25em !default;
$badge-padding-x: 0.6em !default;
// scss-docs-end badge-variables

// Modals
// 
// scss-docs-start modal-variables
$modal-content-border-radius: $border-radius !default;
$modal-content-bg: var(--#{$prefix}body-bg) !default;

$modal-backdrop-bg: var(--#{$prefix}dark) !default;
$modal-backdrop-opacity: .9 !default;
$modal-fade-in-transform: scale(1) !default;
$modal-xs: 12.5rem !default;
// scss-docs-end modal-variables


// Breadcrumbs
// 
// scss-docs-start breadcrumb-variables
$breadcrumb-padding-x: 0 !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: 'transparent';
$breadcrumb-divider: quote("\00BB") !default;
// scss-docs-end breadcrumb-variables


// 
// Sparrow Specific
// 

// Viewport Heights & Widths
// 
// scss-docs-start viewport-heights-map
$viewport-heights: (
  25: 25vh,
  50: 50vh,
  75: 75vh,
  100: 100vh,
) !default;
// scss-docs-end viewport-heights-map

// scss-docs-start viewport-widths-map
$viewport-widths: (
  25: 25vw,
  50: 50vw,
  75: 75vw,
  100: 100vw,
) !default;
// scss-docs-end viewport-widths-map

// scss-docs-start sizes-map
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto,
) !default;
// scss-docs-end sizes-map

// Scrollbar
// 
// scss-docs-start scrollbar-variables
$scrollbar-bg: rgba(var(--#{$prefix}dark-rgb), 0.16) !default;
// scss-docs-end scrollbar-variables

// Fancynav
// 
// scss-docs-start fancynav-variables
$fancynav-width: 4.375rem !default;
$fancynav-height: 3rem !default;
$fancynav-link-color: $white !default;
$fancynav-collapse-bg: $black !default;
$fancynav-togglerbar-bg: $black !default;
// scss-docs-end fancynav-variables

